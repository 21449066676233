<template>
  <div class="newoferta">
    <OfertaForm :oferta="oferta" :type="tipus" />
    <!-- <b-table :items="ofertes"></b-table>-->
  </div>
</template>

<script>
import OfertaForm from "@/components/OfertaForm";

export default {
  name: "OfertaCreate",
  components: {
    OfertaForm,
  },
  data() {
    return {
      oferta: {
        borsa_oferta_empresa_departament_fk: null,
        borsa_oferta_relacio_fk: null,
        borsa_oferta_estudi_fk: null,
        borsa_oferta_durada_fk: null,
        borsa_oferta_horari_fk: null,
        borsa_oferta_categoria_fk: null,
        borsa_oferta_experiencia_fk: null,
      },
      tipus: 1,
    };
  },
  beforeCreate() {
    const temp = this.$store.state.loading ? 500 : 0;
    setTimeout(() => {
      let breadcrumb = [
        {
          text: "Ofertes de Treball",
          to: { name: "OfertaEmpresa" },
        },
        {
          text: "Crea Oferta",
        },
      ];
      this.$store.commit("setBreadcrumb", breadcrumb);
    }, temp);
  },
  methods: {},
};
</script>
<style lang="scss" scoped></style>
