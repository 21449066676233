import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: true,
    anys: [],
    empresa: {},
    students: {},
    empresaCategoria: [],
    empresaActivitat: [],
    empresaSector: [],
    empresaLogo: {},
    departaments: [],
    ofertes: [],
    categoria: [],
    relacioOferta: [],
    estudiOferta: [],
    duradaOferta: [],
    horariOferta: [],
    experienciaOferta: [],
    inscripcions: [],
    convenis: [],
    faqs: [],
    provincia: [],
    breadcrumb: [],
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  },

  mutations: {
    setStudents(state, students) {
      state.students = students;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setAnys(state, anys) {
      state.anys = anys;
    },
    setProvincia(state, prov) {
      state.provincia = prov;
    },
    setFaqs(state, faqs) {
      state.faqs = faqs;
    },
    setBreadcrumb(state, breadcrumb) {
      let bc = [
        {
          text: state.empresa.empresa_nom_comercial,
        },
      ];
      state.breadcrumb = bc.concat(breadcrumb);
    },
    setEmpresa(state, empresa) {
      state.empresa = empresa;
    },
    setEmpresaCategoria(state, empresaCategoria) {
      state.empresaCategoria = empresaCategoria;
    },
    setEmpresaActivitat(state, empresaActivitat) {
      state.empresaActivitat = empresaActivitat;
    },
    setEmpresaSector(state, empresaSector) {
      state.empresaSector = empresaSector;
    },
    setEmpresaLogo(state, empresaLogo) {
      state.empresaLogo = empresaLogo;
    },
    setDepartaments(state, departaments) {
      state.departaments = departaments;
    },
    setOfertes(state, ofertes) {
      state.ofertes = ofertes;
    },
    setInscripcions(state, inscripcions) {
      state.inscripcions = inscripcions;
    },
    setConvenis(state, convenis) {
      state.convenis = convenis;
    },
    setCategoria(state, categories) {
      state.categoria = categories;
    },
    setRelacioOferta(state, relacio) {
      state.relacioOferta = relacio;
    },
    setEstudiOferta(state, estudi) {
      state.estudiOferta = estudi;
    },
    setDuradaOferta(state, durada) {
      state.duradaOferta = durada;
    },
    setHorariOferta(state, horari) {
      state.horariOferta = horari;
    },
    setExperienciaOferta(state, exp) {
      state.experienciaOferta = exp;
    },
  },
  actions: {
    fetchAllPosts({ dispatch, commit }) {
      commit("setLoading", true);
      let empresa = dispatch("fetchEmpresa");
      let students = dispatch("fetchStudents");
      let anys = dispatch("fetchAnys");
      let provincia = dispatch("fetchProvincia");
      let empresaCategoria = dispatch("fetchEmpresaCategoria");
      let empresaActivitat = dispatch("fetchEmpresaActivitat");
      let empresaSector = dispatch("fetchEmpresaSector");
      let empresaLogo = dispatch("fetchEmpresaLogo");
      let departaments = dispatch("fetchDepartaments");
      let ofertes = dispatch("fetchOfertes");
      let inscripcions = dispatch("fetchInscripcions");
      let convenis = dispatch("fetchConvenis");
      let categoria = dispatch("fetchCategoria");
      let relacioOferta = dispatch("fetchRelacioOferta");
      let estudiOferta = dispatch("fetchEstudiOferta");
      let duradaOferta = dispatch("fetchDuradaOferta");
      let horariOferta = dispatch("fetchHorariOferta");
      let experienciaOferta = dispatch("fetchExperienciaOferta");
      let faqs = dispatch("fetchFaqs");
      return Promise.all([
        empresa,
        students,
        anys,
        provincia,
        empresaCategoria,
        empresaSector,
        empresaActivitat,
        empresaLogo,
        departaments,
        ofertes,
        inscripcions,
        convenis,
        categoria,
        relacioOferta,
        estudiOferta,
        duradaOferta,
        horariOferta,
        experienciaOferta,
        faqs,
      ]).then(() => {
        // Both done
        commit("setLoading", false);
      });
    },
    fetchStudents({ state, commit }) {
      console.log("👨🏽‍🎓 🙋🏾‍ Fetch Students");
      return new Promise((resolve) => {
        axios.get("/persona/students").then((res) => {
          const students = res.data;
          commit("setStudents", students);
          resolve(state.students);
        });
      });
    },
    fetchFaqs({ state, commit }) {
      console.log("📦 🙋🏾‍ Fetch Faqs");
      return new Promise((resolve) => {
        axios.get("/empresa/faqs").then((res) => {
          const faqs = res.data;
          commit("setFaqs", faqs);
          resolve(state.faqs);
        });
      });
    },
    fetchProvincia({ state, commit }) {
      console.log("📦 🙋🏾‍ Fetch Provincia");
      return new Promise((resolve) => {
        axios.get("/provincia").then((res) => {
          const provincia = res.data;
          commit("setProvincia", provincia);
          resolve(state.provincia);
        });
      });
    },
    fetchEmpresa({ state, commit }) {
      console.log("📦 🏢 Fetch Empresa");
      return new Promise((resolve) => {
        axios.get("/empresa").then((res) => {
          const empresa = res.data;
          commit("setEmpresa", empresa);
          resolve(state.empresa);
        });
      });
    },
    fetchAnys({ state, commit }) {
      console.log("📦 ⏳ Fetch Anys");
      return new Promise((resolve) => {
        axios.get("/any").then((res) => {
          const anys = res.data;
          commit("setAnys", anys);
          resolve(state.anys);
        });
      });
    },
    fetchEmpresaCategoria({ state, commit }) {
      console.log("📦 🏢 Fetch Empresa Categoria");
      return new Promise((resolve) => {
        axios.get("/empresa/categoria").then((res) => {
          const cat = res.data.map(function (item) {
            return {
              value: item.empresa_categoria_id,
              text: item.empresa_categoria_nom_ca,
            };
          });
          commit("setEmpresaCategoria", cat);
          resolve(state.empresaCategoria);
        });
      });
    },
    fetchEmpresaActivitat({ state, commit }) {
      console.log("📦 🏢 Fetch Empresa Activitat");
      return new Promise((resolve) => {
        axios.get("/empresa/activitat").then((res) => {
          const activitat = res.data;
          /* .map(function(item) {
            return {
              value: item.empresa_activitat_id,
              text: item.empresa_activitat_nom_ca
            };
          });*/
          commit("setEmpresaActivitat", activitat);
          resolve(state.empresaActivitat);
        });
      });
    },
    fetchEmpresaSector({ state, commit }) {
      console.log("📦 🏢 Fetch Empresa Sector");
      return new Promise((resolve) => {
        axios.get("/empresa/sector").then((res) => {
          const sector = res.data.map(function (item) {
            return {
              value: item.empresa_sector_id,
              text: item.empresa_sector_nom_ca,
            };
          });
          commit("setEmpresaSector", sector);
          resolve(state.empresaSector);
        });
      });
    },
    fetchEmpresaLogo({ state, commit }) {
      console.log("📦 🏢 Fetch Empresa Logo");
      return new Promise((resolve) => {
        axios.get("/empresa/logo").then((res) => {
          const logo = res.data;
          commit("setEmpresaLogo", logo);
          resolve(state.empresaLogo);
        });
      });
    },
    fetchDepartaments({ state, commit }) {
      console.log("📦 💼 Fetch Departaments");
      return new Promise((resolve) => {
        axios.get("/empresa/departament").then((res) => {
          const departaments = res.data;
          console.log("📦 💼 Fetch Departaments", departaments);
          commit("setDepartaments", departaments);
          resolve(state.departaments);
        });
      });
    },
    fetchOfertes({ state, commit }) {
      console.log("📦 🏷 Fetch Ofertes");
      return new Promise((resolve) => {
        axios.get("/empresa/borsa").then((res) => {
          const ofertes = res.data;
          commit("setOfertes", ofertes);
          resolve(state.ofertes);
        });
      });
    },
    fetchConvenis({ state, commit }) {
      console.log("📦 🧾 Fetch Convenis");
      return new Promise((resolve) => {
        axios.get("/empresa/conveni").then((res) => {
          const convenis = res.data;
          commit("setConvenis", convenis);
          resolve(state.convenis);
        });
      });
    },
    // fetchInscripcions({ commit }, { id }) {
    //   console.log("📦 🙋🏾‍ Fetch Inscripcions");
    //   return new Promise(resolve => {
    //     axios.get("/borsa/"+id+"/inscripcio").then(res => {
    //       const inscripcions = res.data;
    //       commit("addInscripcions", inscripcions);
    //       resolve(inscripcions);
    //     });
    //   });
    // },
    fetchInscripcions({ state, commit }) {
      console.log("📦 🙋🏾‍ Fetch Inscripcions");
      return new Promise((resolve) => {
        axios.get("/empresa/inscripcio").then((res) => {
          const inscripcions = res.data;
          commit("setInscripcions", inscripcions);
          resolve(state.inscripcions);
        });
      });
    },
    fetchCategoria({ state, commit }) {
      console.log("📦 🏢 Fetch Categoria");
      return new Promise((resolve) => {
        axios.get("/categoria").then((res) => {
          const categories = res.data;
          console.log(categories);
          commit("setCategoria", categories);
          resolve(state.categoria);
        });
      });
    },
    fetchRelacioOferta({ state, commit }) {
      console.log("📦 🏢 Fetch Relacio Oferta");
      return new Promise((resolve) => {
        axios.get("/empresa/borsa/relacio").then((res) => {
          const rel = res.data;
          commit("setRelacioOferta", rel);
          resolve(state.relacioOferta);
        });
      });
    },
    fetchEstudiOferta({ state, commit }) {
      console.log("📦 🏢 Fetch Estudi Oferta");
      return new Promise((resolve) => {
        axios.get("/empresa/borsa/estudi").then((res) => {
          const rel = res.data;
          commit("setEstudiOferta", rel);
          resolve(state.estudiOferta);
        });
      });
    },
    fetchDuradaOferta({ state, commit }) {
      console.log("📦 🏢 Fetch Durada Oferta");
      return new Promise((resolve) => {
        axios.get("/empresa/borsa/durada").then((res) => {
          const durada = res.data;
          commit("setDuradaOferta", durada);
          resolve(state.duradaOferta);
        });
      });
    },
    fetchHorariOferta({ state, commit }) {
      console.log("📦 🏢 Fetch Horari Oferta");
      return new Promise((resolve) => {
        axios.get("/empresa/borsa/horari").then((res) => {
          const horari = res.data;
          commit("setHorariOferta", horari);
          resolve(state.horariOferta);
        });
      });
    },
    fetchExperienciaOferta({ state, commit }) {
      console.log("📦 🏢 Fetch Experiencia Oferta");
      return new Promise((resolve) => {
        axios.get("/empresa/borsa/experiencia").then((res) => {
          const experiencia = res.data;
          commit("setExperienciaOferta", experiencia);
          resolve(state.experienciaOferta);
        });
      });
    },
  },
  getters: {
    anys(state) {
      return state.anys;
    },
    students(state) {
      return state.students;
    },
    faqs(state) {
      return state.faqs;
    },
    provincia(state) {
      return state.provincia;
    },
    empresa(state) {
      return state.empresa;
    },
    empresaCategoria(state) {
      return state.empresaCategoria;
    },
    empresaActivitat(state) {
      return state.empresaActivitat;
    },
    empresaSector(state) {
      return state.empresaSector;
    },
    empresaLogo(state) {
      return state.empresaLogo;
    },
    departaments(state) {
      return state.departaments;
    },
    ofertes(state) {
      return state.ofertes;
    },
    ofertaId: (state) => (id) => {
      return state.ofertes.find((o) => o.borsa_oferta_id === id);
    },
    relacioOferta(state) {
      return state.relacioOferta;
    },
    estudiOferta(state) {
      return state.estudiOferta;
    },
    experienciaOferta(state) {
      return state.experienciaOferta;
    },
    horariOferta(state) {
      return state.horariOferta;
    },
    duradaOferta(state) {
      return state.duradaOferta;
    },
    categoria(state) {
      return state.categoria;
    },
    inscripcions(state) {
      return state.inscripcions;
    },
    convenis(state) {
      return state.convenis;
    },
    breadcrumb(state) {
      return state.breadcrumb;
    },
    customToolbar(state) {
      return state.customToolbar;
    },
  },
  modules: {},
});
